<template>
  <div id="app">
    <router-view class="page" />
    <StyledFlyout />
  </div>
</template>

<script>
// TODO add loading overlay styles
// TODO add notification logic

// eslint-disable-next-line no-unused-expressions
// eslint-disable-next-line no-unused-vars
// import { styles } from '@/theme';

// styles;

import { StyledFlyout } from '@/theme';

export default {
  components: {
    StyledFlyout,
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/theme';
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.page {
  min-height: 100vh;
}
.notify {
  position: absolute;
  right: 0;
}
</style>
